<template>
  <div id="report">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <p
        v-for="(item, index) in list"
        :key="index"
        @click="
          $router.push({
            name: 'Report_Index',
            query: { r_id: item.id },
          })
        "
      >
        {{ item.title }}
        <van-icon name="arrow" class="icon" />
      </p>
    </van-list>
  </div>
</template>

<script>
import { List, Icon } from "vant";
import { get } from "@get/http.js";
export default {
  components: {
    vanIcon: Icon,
    vanList: List,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      pageSize: 20,
    };
  },
  created() {},
  methods: {
    async getList() {
      let result = await get(
        "/Inspection/index",

        {
          params: {
            store_id: process.env.VUE_APP_STORE,
            page: this.page,
            pageSize: this.pageSize,
          },
        }
      );
      this.list.push(...result.data);
      this.loading = false;
      this.page = this.page + 1;
      if (result.data.length < this.pageSize) {
        this.finished = true;
      }
    },
    onLoad() {
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
#report {
  padding-top: 0.6rem;
}
p {
  background: #fff;
  padding: 0.8rem 0.5rem;
  box-sizing: border-box;
  width: 95%;
  border-radius: 0.4rem;
  font-size: 14px;
  margin: 0 auto 0.5rem;
}
.icon {
  float: right;
  margin-top: 0.2rem;
  color: #b3b3b3;
}
</style>
<style lang="scss">
.van-list__finished-text {
  color: #969799;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
}
</style>
